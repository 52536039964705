/** @format */
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body,
input,
button {
  font-family: Quicksand, "sans-serif" !important;
}

header {
  background-image: radial-gradient(
    circle farthest-corner at 100% 100%,
    #fd1d3e,
    #ff9818,
    #ffe05e
  );
}

.call-reports__reason {
  width: 250px;
}

thead span {
  font-weight: bold;
}

.premium {
  background-image: radial-gradient(
    circle farthest-corner at 100% 100%,
    #fd1d3e,
    #ff9818,
    #ffe05e
  );
  color: white;
  padding: 2px 10px;
  border-radius: 10px;
  font-weight: 600;
}

.free {
  background: rgb(246, 246, 248);
  color: rgb(71, 68, 84);
  padding: 2px 10px;
  border-radius: 10px;
  font-weight: 600;
}

.new {
  background: #d1eeff !important;
  color: #009dff !important;
  border-radius: 10px;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}

.in_progress {
  background: #fff3d1 !important;
  color: #ffbf00 !important;
  border-radius: 10px;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}

.done {
  background: #d9ffd1 !important;
  color: #2ed600 !important;
  border-radius: 10px;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}

a {
  /* color: #07f !important; */
  font-weight: bold;
}
